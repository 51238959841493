<template>
  <div class="provider page-wrap">
    <a-spin :spinning="loading">
      <a-empty v-if="provider.length == 0" description="暂无信息"></a-empty>
      <div v-else class="wrapper provider-box">
        <template v-for="(item, index) in provider" :key="index">
          <div>
            <div class="provider-type">{{ item.provider_type_name }}</div>
            <div
              v-for="(item2, index2) in item.provider"
              :key="index2"
              class="flex flex-between provider-list"
              @click="goToProviderDetail(item2)"
            >
              <div class="flex provider-left">
                <img :src="item2.provider_logo" />
                <div>
                  <div class="provider-name">{{ item2.provider_name }}</div>
                  <div class="provider-desc">{{ item2.provider_desc }}</div>
                </div>
              </div>
              <RightOutlined />
            </div>
          </div>
        </template>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { getProviderList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "Provider",
  components: {
    RightOutlined
  },
  setup() {
    const pageData = reactive({
      provider: [],
      loading: false
    });
    const getProviderListFun = () => {
      pageData.loading = true;
      getProviderList()
        .then(res => {
          if (res.code == 200) {
            pageData.provider = res.data.provider_type;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getProviderListFun();
    const goToProviderDetail = _item => {
      url.navigateTo("/provider/detail", {
        t: encode(`${_item.provider_id}`)
      });
    };

    return {
      ...toRefs(pageData),
      goToProviderDetail
    };
  }
});
</script>
<style lang="less" scoped>
.provider {
  margin-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .provider-box {
    .provider-type {
      font-size: 28px;
      color: #212531;
      position: relative;
      padding-left: 20px;
      margin-bottom: 20px;
      margin-top: 30px;
      &::before {
        content: "";
        width: 4px;
        height: 24px;
        background-color: #1276cb;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .provider-list {
      width: 100%;
      height: 151px;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 0 30px;
      box-shadow: 0px 0px 27px 0px #eeeeee;
      margin-top: 10px;
      cursor: pointer;
      .provider-left {
        img {
          width: 80px;
          height: 80px;
          background-color: #ffffff;
          border: solid 2px #f3f5f8;
          border-radius: 80px;
          margin-right: 20px;
        }
        .provider-name {
          color: #212531;
          font-size: 22px;
        }
        .provider-desc {
          color: #212531;
          opacity: 0.6;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
